import { toast } from "react-toastify";

export interface Notification {
  message?: string;
  error?: string;
}

export function Notify(notification: Notification) {
  if (notification.error) {
    toast.error(notification.error, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000
    });
  } else if (notification.message) {
    toast.info(notification.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000
    });
  }
};
