import { useCallback, useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, InputGroup, Stack } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
// import { LogService } from '../services/log.service';
import { progressService } from '../../services/progress.service';
import { TwinsActionService } from '../../services/twins-action.service';

import { PageTitle } from '../../PageTitle';
import { setAuthorization } from '../../services/auth.service';



export function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [wait, setWait] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    setAuthorization(username, password);
    const unsubscriber = progressService.subscribe(setWait);
    TwinsActionService({
      data: {
        action: "login",
        params: {}
      }
    }).then((data) => {
      setSearchParams({ page: "admin" });
    }).catch((err) => {
      setAuthorization(null, null);
    }).finally(unsubscriber);
  }

  // useEffect(() => {
  //   // const timeOutId = setTimeout(() => login(), 500);
  //   // return () => clearTimeout(timeOutId);
  //   login();
  // }, [login]);

  const usernameLabel = "Имя пользователя";
  const passwordLabel = "Пароль";
  return (
    <>
      <PageTitle
        title={"Введите логин и пароль"}
      />

      <Stack className={"debug"} gap={3}>
        <FloatingLabel label={usernameLabel}>
          <Form.Control type="text" placeholder={usernameLabel}
            onChange={(event) => setUsername(event.target.value)}
            value={username}
            className="fs-4" />
        </FloatingLabel>

        <FloatingLabel label={passwordLabel}>
          <Form.Control type="password" placeholder={passwordLabel}
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            className="fs-4" />
        </FloatingLabel>

        <Col xs="auto">
          <span className="btn btn-outline-primary fs-5" onClick={login}>Войти</span>&nbsp;
          <a className="btn btn-outline-primary fs-5" href="/?page=comments">Отмена</a>
        </Col>
      </Stack>
    </>
  );
}