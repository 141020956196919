import { FC, ReactElement, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Add from './pages/Add';
import AddComment from './pages/AddComment';
import { Authors } from './pages/Authors';
import { Comments } from './pages/Comments';
import { Login } from './pages/admin/Login';
import { Search } from './pages/Search';

export class NavItem {
  title!: string;
  url?: string;
  pageName?: string;
  icon?: ReactElement;
  page!: ReactElement;
  description?: string;
  disabled?: boolean;
  hidden?: boolean = false;
}

type AppNavigationProps = {
}

const ExitMenuItem = {
  title: 'Вход',
  pageName: "login",
  page: <Login />,
  hidden: false,
}

export const AppNavigationItems: NavItem[] = [
  {
    title: 'Поиск',
    pageName: "search",
    page: <Search />,
  },
  {
    title: 'Добавить плагиат',
    pageName: "add",
    page: <Add />,
    hidden: true,
  },
  {
    title: 'Общение',
    pageName: "comments",
    page: <Comments />,
  },
  {
    title: 'Добавить коммент',
    pageName: "add_comment",
    page: <AddComment />,
    hidden: true,
  },
  {
    title: 'Авторы',
    pageName: "authors",
    page: <Authors />,
    hidden: false,
  },
  //ExitMenuItem
];

const NavigationItem: FC<NavItem> = ({
  title,
  pageName,
  icon,
  description,
  disabled,
  hidden
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const path = searchParams.get("page") || "search";
  const clazz = path === pageName ? "btn btn-light fs-5" : "btn btn-link btn-light fs-5";
  return (
    <li className="nav-item"><a href={`?page=${pageName}`} className={clazz}>{title}</a></li>
  );
};

const AppNavigation: FC<AppNavigationProps> = () => {

  const navigationItemsData: NavItem[] = useMemo(
    () => AppNavigationItems,
    [],
  );
  // "border-bottom-left-radius: 18px; border-bottom-right-radius: 18px; border-top-left-radius: 18px; border-top-right-radius: 18px"
  return (
    <>
      <ul className="nav nav-pills debug">
        {navigationItemsData.filter((item) => { return !item.hidden }).map(
          (props) => {
            return (
              <NavigationItem
                {...props} key={props.pageName}
              />
            );
          },
        )}
        <li className="nav-item">
          <a href="https://t.me/+4hSPAylwJWs1NzMy" title="muz-plagiat.ru" className="btn btn-link btn-light fs-5" target="_blank">
            <img src="/telegram.jpg" className="telegram-icon mr-2 pr-2"></img>
            <span className="small">muz-plagiat.ru</span>
          </a>
        </li>
      </ul>
    </>
  );
}

export default AppNavigation;
