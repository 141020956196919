export enum LogLevel {
  OFF,
  ERROR,
  WARN,
  INFO,
  DEBUG,
}

//type LogLevelStrings = "OFF" | 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';
export type LogLevels = keyof typeof LogLevel;

export class LogService {

  private name: string = 'ROOT';
  private static enable: boolean = false;

  constructor(owner: any, private level: LogLevels) {
    if (owner instanceof Function) {
      this.name = owner.name;
    } else {
      this.name = owner?.constructor ? owner.constructor.name : '' + owner;
    }
  }

  static setEnable(enable: boolean) {
    LogService.enable = enable;
  }

  setLevel(level: LogLevels | undefined) {
    if (level) {
      this.level = level;
    }
  }

  error(message?: any, ...optionalParams: any[]) {
    this._print("ERROR", console.error, message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    this._print("WARN", console.warn, message, ...optionalParams);
  }

  info(message?: any, ...optionalParams: any[]) {
    this._print("INFO", console.info, message, ...optionalParams);
  }

  debug(message?: any, ...optionalParams: any[]) {
    // console.debug need change log filtering at browser
    this._print("DEBUG", console.log, message, ...optionalParams);
  }

  private _print(level: LogLevels, provider: Function, message?: any, ...optionalParams: any[]) {
    if (LogService.enable) {
      if (LogLevel[level] > LogLevel[this.level]) {
        return;
      }
      if (message !== undefined) {
        if (optionalParams === undefined) {
          provider(level + " " + this.name + " " + message);
        } else {
          provider(level + " " + this.name + " " + message, ...optionalParams);
        }
      }
    }
  }
}