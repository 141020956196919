import { useState } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { progressService } from '../../services/progress.service';
import { TwinsActionService } from '../../services/twins-action.service';
import { GoogleYandexLinks, TwinsListItemProps } from '../TwinsListItem';

export function ApproveTwinsListItem(props: TwinsListItemProps) {
  const [wait, setWait] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const action = (action: string) => {
    const unsubscribe = progressService.subscribe(setWait);
    TwinsActionService({
      data: {
        action: action,
        params: { id: props.id }
      }
    }).then((result) => {
      props.updateItem({ ...props, action: action });
    }).catch((err) => {
    }).finally(unsubscribe);
  };

  const edit = () => {
    props.updateItem({ ...props, action: "edit" });
  }

  const approveAction = props.approved === "1" ? "unapprove" : "approve";
  const approveTitle = props.approved === "1" ? "Запретить" : "Одобрить";
  const approveClass = props.approved === "1" ? "btn btn-outline-danger" : "btn btn-outline-success";

  return (
    <Card border="secondary" className="mb-3 mt-3">
      <Card.Body>
        <Card.Title>
          <Row>
            <Col><Card.Link className="" href={`/?page=admin&approve=false&text=${encodeURIComponent(props.singer_one)}`}>{props.singer_one}</Card.Link></Col>
            <Col><Card.Link className="" href={`/?page=admin&text=${encodeURIComponent(props.singer_two)}`}>{props.singer_two}</Card.Link></Col>
          </Row>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <Row><Col>{props.song_one}</Col><Col>{props.song_two}</Col></Row>
          <Row className="small">
            <Col>
              <GoogleYandexLinks search={props.singer_one + " " + props.song_one} />
            </Col>
            <Col>
              <GoogleYandexLinks search={props.singer_two + " " + props.song_two} />
            </Col>
          </Row>
        </Card.Subtitle>
        <pre dangerouslySetInnerHTML={{ __html: props.note }} className="fw-lighter" style={{ whiteSpace: 'pre-wrap' }}></pre>
        {/* <br /> */}
        {/* <div dangerouslySetInnerHTML={{ __html: props.all_for_index }}></div>
        <br /> */}
        <Row className="small debug">
          <Col xs={9}>
            <button onClick={() => edit()} className="btn btn-outline-primary">Изменить</button>&nbsp;
            <button onClick={() => action(approveAction)} className={approveClass}>{approveTitle}</button>&nbsp;
            <button onClick={() => action("delete")} className="btn btn-outline-danger">Удалить</button>
          </Col>
          <Col>
            <Badge bg="success" text="dark" className="mt-1 float-end">{props.vote_pro}</Badge>
            <Badge bg="danger" text="dark" className="mt-1 float-end">{props.vote_con}</Badge>
            <Badge bg="light" text="dark" className="mt-1 float-end">{new Date(props.created_time).toLocaleDateString("ru")}</Badge>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
