export interface PageTitleProps {
  title: string;
  RightSideComponent?: React.ReactNode | null;
}

export function PageTitle(props: PageTitleProps) {

  return (//<></>
    <div className="row debug m-0 mb-3">
      <div className="col debug">
        <div className="debug fs-3 text-muted">{props.title}</div>
      </div>
      {props.RightSideComponent &&
        <div className="col-auto p-0 pt-1 debug">
          {props.RightSideComponent}
        </div>
      }
    </div>

  )
}
