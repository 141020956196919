import { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { LogService } from '../services/log.service';
import { progressService } from '../services/progress.service';
import { PhraseActionService } from '../services/twins-action.service';
import { Search as SearchIcon } from 'react-feather';

export interface AddSingerProps {
  updateValue: (value: string) => void;
  isInvalid?: () => boolean | undefined;
  value?: string;
}

export function AddSinger(props: AddSingerProps) {
  const log = new LogService(AddSinger, "DEBUG");
  const [phrase, setPhrase] = useState<any[]>([]);
  const [wait, setWait] = useState(false);
  const [value, setValue] = useState(props.value || "");

  useEffect(() => {
    props.updateValue(value);
  }, [value, props]);

  const search = () => {
    const unsubscriber = progressService.subscribe(setWait);
    PhraseActionService({
      data: {
        action: "search",
        params: { category: "SINGER", value: value }
      }
    }).then((data) => {
      log.debug("get phrase");
      setPhrase(data.result);
    }).catch((err) => {
    }).finally(unsubscriber);
  }

  return (<>
    <InputGroup className="">
      <FloatingLabel label="Введите имя исполнителя">
        <Form.Control type="text" placeholder="Введите имя исполнителя"
          onChange={(event) => setValue(event.target.value)}
          value={value} isInvalid={props.isInvalid && props.isInvalid()}
          className="fs-5" />
      </FloatingLabel>
      <Button variant="outline-secondary" onClick={search}><SearchIcon /></Button>
    </InputGroup>
    {phrase && phrase.length > 0 &&
      <><p>Выберите исполнителя из списка</p>
        <Form.Select aria-label="Выберите исполнителя из списка" multiple={true} onChange={(event) => setValue(event.target.value)}>
          {
            phrase.map((item, i) => {
              return <option key={i}>{item.value}</option>
            })
          }
        </Form.Select>
      </>}
  </>);
}