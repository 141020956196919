import { BrowserRouter } from 'react-router-dom';


import './App.css';
import Keywords from './pages/Keywords';
import Top from './pages/Top';
import { LogService } from './services/log.service';

import 'react-toastify/dist/ReactToastify.css';
import VoteTop from './pages/VoteTop';
import Stat from './pages/Stat';
import { getAuthorization } from './services/auth.service';

LogService.setEnable(true);
function AppBottomRightSide() {
  const authorization = getAuthorization();
  return (
    <BrowserRouter>
      <Top />
      <VoteTop />
      <Stat />
    </BrowserRouter>
  )
}

export default AppBottomRightSide;
