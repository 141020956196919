import base64 from 'react-native-base64';

export function setAuthorization(username: string | null, password: string | null) {
    if (username) {
        const authorization = "Basic " + base64.encode(username + ":" + password);
        localStorage.setItem('authorization', authorization);
        localStorage.setItem('username', username);
    } else {
        localStorage.removeItem('authorization');
    }
}

export function getAuthorization() {
    return localStorage.getItem('authorization');
}

export function getUsername() {
    return localStorage.getItem('username');
}