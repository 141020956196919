import { Notify } from "../Notifications";
import { getAuthorization } from "./auth.service";
import { LogService } from './log.service';
import { progressStart, progressStop } from "./progress.service";

export interface Headers {
    headers: Record<string, string>;
}

export type Method = 'DELETE' | 'GET' | 'POST' | 'PUT';

export type ErrorMode = 'SILENT' | 'TOAST' | 'ERROR';

export class RequestParams {
    url?: string;
    method?: Method;
    data?: string | any;
    headers?: object;
    errorMode?: ErrorMode;
}

export async function ApiService(params: RequestParams) {
    // const { authorization, setAuthorization } = useAuthorization();
    const log = new LogService(ApiService, "DEBUG");
    params = { errorMode: 'TOAST', ...params };
    log.debug("entry", params);
    progressStart();

    const options: any = {
        method: params.method,
        mode: "cors",
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json; charset=utf-8',
            //'Access-Control-Allow-Origin': '*',
            //...this.basicAuthHeader
            // 'Authorization': 'Basic YXBpOmdoanVoZnZ2Zg=='
        },
        body: JSON.stringify(params.data)
    };

    if (getAuthorization()) {
        options.headers = {
            ...options.headers,
            'Authorization': getAuthorization()
        }
        log.debug("authorization added");
    }

    try {
        const response: Response = await fetch(params?.url || "http://undefined", options);

        // response.headers.forEach((item)=>{log.debug("HEADER")});
        // OR you can do this
        // for(let item of response.headers.entries()) {
        //     log.debug(entry);
        // }

        if (isError(response)) {
            const payload = await response.json();
            log.error("fetch error", payload);
            if (params.errorMode === 'TOAST') {
                Notify(payload);
            }
            if (params.errorMode === 'SILENT') {
                return Promise.resolve({});
            }
            return Promise.reject(payload);
        } else {
            const payload = await response.json();
            log.debug("fetch success", payload);
            // log.debug("response headers:", response.headers);
            return Promise.resolve(payload);
        }
    } catch (e) {
        log.error("fetch catch error", params.url, e);
        if (params.errorMode === 'TOAST') {
            Notify({ "error": "" + params?.data?.component + `[${params?.data?.action}] - ` + e } );
        }
        
        if (params.errorMode === 'SILENT') {
            return Promise.resolve({});
        }
        return Promise.reject({ "error": e });
    } finally {
        progressStop();
    }
}

// TODO add 501 for user displayed messages and 500 - Внутренняя ошибка сервера
const isError = (response: Response) => {
    return [401, 403, 500].some(x => x === response.status)
}

// const isUnauthorized = (response: Response) => {
//     return [401].some(x => x === response.status)
// }
