import { useCallback, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
// import { LogService } from '../services/log.service';
import { progressService } from '../../services/progress.service';
import { TwinsActionService } from '../../services/twins-action.service';
import { ApproveTwinsListItem } from './ApproveTwinsListItem';
import { TwinsListItemPlaceholder } from '../TwinsListItemPlaceholder';
import { Search as SearchIcon, X as XIcon } from 'react-feather';
import { TwinsListItemProps } from '../TwinsListItem';
import UpdateTwins, { UpdateTwinsItemProps } from './UpdateTwins';
import { PageTitle } from '../../PageTitle';

export function ApproveTwins() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [twins, setTwins] = useState<any[]>([]);
  const [wait, setWait] = useState(false);
  const [editItem, setEditItem] = useState<UpdateTwinsItemProps>();
  const [subPage2, setSubPage2] = useState(searchParams.get("sub-page2") || "");
  const [searchValue, setSearchValue] = useState(searchParams.get("text") || "");
  const [toApprove, setToApprove] = useState<boolean>(searchParams.get("approve") === "true" ? true : false);
  const currentSearchParams: any = { page: "admin", "sub-page": "twins", }

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);
    let newSearchParams: any = currentSearchParams
    if (searchValue) {
      newSearchParams = { ...newSearchParams, text: searchValue };
    }

    if (toApprove) {
      newSearchParams = { ...newSearchParams, approve: "" + toApprove };
    }

    if (subPage2) {
      newSearchParams = { ...newSearchParams, "sub-page2": subPage2 };
    }

    setSearchParams({ ...newSearchParams });

    if (subPage2 && subPage2 !== "approve") {
      return;
    }

    let params: any = { search: searchValue }
    if (toApprove) {
      params = { toApprove: toApprove }
    }

    TwinsActionService({
      data: {
        action: "search",
        params: params
      }
    }).then((data) => {
      if (data.result) {
        setTwins(data.result);
      } else {
        setTwins([]);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, [searchValue, toApprove])

  useEffect(() => {
    const timeOutId = setTimeout(() => search(), 500);
    return () => clearTimeout(timeOutId);
  }, [search, setTwins]);

  const updateItem = (updatedItem: UpdateTwinsItemProps) => {
    let newList = [];
    if (updatedItem.action === "edit") {
      setEditItem(updatedItem);
      setSubPage2("edit")
      setSearchParams({ ...currentSearchParams, "sub-page2": "edit" });
    } else if (updatedItem.action in ["approve", "unapprove"]) {
      newList = twins.map((item: any) => {
        if (item.id === updatedItem.id) {
          return { ...updatedItem };
        } else {
          return item;
        }
      });
      setTwins(newList);
    } else if (updatedItem.action === "delete") {
      newList = twins.filter((item: any) => {
        if (item.id !== updatedItem.id) {
          return item;
        }
      });
      setTwins(newList);
    }
  }

  const approveTwins = () => {
    const searchLabel = "Для поиска введите исполнителя, группу или песню";
    return (
      <>
        <PageTitle title={"Плагиат"} />
        <InputGroup className="mb-3">
          <FloatingLabel label={searchLabel}>
            <Form.Control type="text" placeholder={searchLabel}
              onChange={(event) => setSearchValue(event.target.value)}
              value={searchValue}
              className="fs-4" />
          </FloatingLabel>
          <Button variant="outline-secondary" onClick={() => setSearchValue("")}><XIcon /></Button>
          <Button variant="outline-secondary" onClick={search}><SearchIcon /></Button>
        </InputGroup>
        <div className="form-check fs-4">
          <input
            checked={toApprove}
            onChange={(event) => setToApprove(event.target.checked)}
            className="form-check-input" type="checkbox" id="toApprove" />
          <label className="form-check-label" htmlFor="toApprove">
            На утверждение
          </label>
        </div>

        <div className="debug">
          {wait && [1, 2, 3].map((value) => { return (<TwinsListItemPlaceholder key={value} />); })}
          {!wait &&
            twins.map(
              (item: any) => {
                return (
                  <ApproveTwinsListItem {...item} key={item.id} updateItem={updateItem} />
                );
              },
            )
          }
        </div>
      </>
    )
  }


  return (
    <>
      {
        {
          "approve": approveTwins(),
          "edit": editItem && <UpdateTwins {...editItem} />,
        }[subPage2] || approveTwins()
      }
    </>
  );
}