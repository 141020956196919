import { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

export interface AddSongProps {
  updateValue: (value: string) => void;
  isInvalid?: () => boolean | undefined;
  value?: string;
}

export function AddSong(props: AddSongProps) {
  const [value, setValue] = useState(props.value || "");

  useEffect(() => {
    props.updateValue(value);
  }, [value, props]);

  return (<>
    <FloatingLabel label="Введите песню">
      <Form.Control type="text" placeholder="Введите песню"
        onChange={(event) => setValue(event.target.value)}
        value={value} isInvalid={props.isInvalid && props.isInvalid()}
        className="fs-5" />
    </FloatingLabel>
  </>);
}