import { useCallback, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { progressService } from '../services/progress.service';
import { TwinsActionService } from '../services/twins-action.service';
import { TwinsListItem, TwinsListItemProps } from './TwinsListItem';
import { TwinsListItemPlaceholder } from './TwinsListItemPlaceholder';
import { Search as SearchIcon, X as XIcon } from 'react-feather';
import { PageTitle } from '../PageTitle';

export function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [twins, setTwins] = useState<any[]>([]);
  const [wait, setWait] = useState(false);
  const [searchValue, setSearchValue] = useState(searchParams.get("text") || "");

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);
    if (searchValue) {
      setSearchParams({ page: "search", text: searchValue });
    } else {
      setSearchParams({ page: "search" });
    }

    TwinsActionService({
      data: {
        action: "search",
        params: { search: searchValue }
      }
    }).then((data) => {
      if (data.result) {
        setTwins(data.result);
      } else {
        setTwins([]);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, [searchValue, setTwins])

  useEffect(() => {
    // search();
    const timeOutId = setTimeout(() => search(), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const updateItem = (updatedItem: TwinsListItemProps) => {
    const newList = twins.map((item: any) => {
      if (item.id === updatedItem.id) {
        return { ...updatedItem };
      } else {
        return item;
      }
    });
    setTwins(newList);
  }

  const searchLabel = "Для поиска введите исполнителя, группу или песню";
  return (
    <>
      <PageTitle
        title={searchValue ? (twins.length === 0 ? "Ничего не найдено по запросу" : "Найдено по запросу") : "Свежий плагиат"}
        RightSideComponent={<a href="/?page=add" className="btn btn-outline-primary">Новый плагиат</a>}
      />

      <InputGroup className="mb-3">
        <FloatingLabel label={searchLabel}>
          <Form.Control type="text" placeholder={searchLabel}
            onChange={(event) => setSearchValue(event.target.value)}
            value={searchValue}
            className="fs-4" />
        </FloatingLabel>
        <Button variant="outline-secondary" onClick={() => setSearchValue("")}><XIcon /></Button>
        <Button variant="outline-secondary" onClick={search}><SearchIcon /></Button>
      </InputGroup>

      <div className="debug">
        {wait && [1, 2, 3].map((value) => { return (<TwinsListItemPlaceholder key={value} />); })}
        {!wait &&
          twins.map(
            (item: any) => {
              return (
                <TwinsListItem {...item} key={item.id} updateItem={updateItem} />
              );
            },
          )
        }
      </div>
    </>
  );
}