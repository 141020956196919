import React, { } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { AppNavigationItems } from './Navigation';
import Add from './pages/Add';
import AddComment from './pages/AddComment';
import { Authors } from './pages/Authors';
import { Comments } from './pages/Comments';
import PageNotFound from './pages/PageNotFound';
import { Search } from './pages/Search';
import { Admin } from './pages/admin/Admin';

// function Add(props: AddProps) {
function PageContent() {
    const [searchParams, setSearchParams] = useSearchParams();
    const param: string = searchParams.get("page") || "search";
    return <>
    {
        {
            "search": <Search/>,
            "add": <Add/>,
            "comments": <Comments/>,
            "add_comment": <AddComment/>,
            "authors": <Authors/>,
            "admin": <Admin/>,
        }[param] || <PageNotFound/>
    }
    </>;
}

export function Content() {
    return (<>{
        <Routes>
            <Route path="/" element={<PageContent />}/>
        </Routes>
    }
    </>
        // <Routes>
        //     {AppNavigationItems.map(
        //         (props) => {
        //             return (
        //                 <Route path={props.url} element={page} key={props.url} />
        //             );
        //         },
        //     )}
        // </Routes>
    );
}