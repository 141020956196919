import { useState } from 'react';

import { Button, Container, Row, Col, Badge, Stack, Modal } from 'react-bootstrap';
import { LogService } from '../services/log.service';
import { TwinsListItemProps } from './TwinsListItem';
import { progressService } from '../services/progress.service';
import { Captcha } from './Captcha';
import { TwinsActionService } from '../services/twins-action.service';


export function TwinsListItemVote(props: TwinsListItemProps) {
  const log = new LogService(TwinsListItemVote, "DEBUG");
  const [show, setShow] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const votePro = () => vote(true);
  const voteCon = () => vote(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [wait, setWait] = useState(false);

  const onCaptchaChanged = (value: string) => {
    setCaptchaValue(value);
  }

  const vote = (vote: boolean) => {
    const unsubscribe = progressService.subscribe(setWait);
    TwinsActionService({
      data: {
        action: "vote",
        params: { id: props.id, vote: vote, captcha: captchaValue }
      }
    }).then((result) => {
      log.debug("vote ok", result);
      setShow(false);
      const vote_pro = (vote ? additionInt(props.vote_con, 1) : props.vote_con);
      const vote_con = (vote ? props.vote_pro : additionInt(props.vote_pro, 1));
      const item = { ...props, vote_con, vote_pro };
      props.updateItem(item);
    }).catch((err) => {
    }).finally(unsubscribe);
  };

  const result = Number.parseInt(props.vote_pro) - Number.parseInt(props.vote_con);
  const bg = result > 0 ? "success" : (result < 0 ? "danger" : "secondary");

  return (
    <>
      <Stack direction="horizontal" className="d-inline">
        <Button variant="link" size="sm" onClick={handleShow} style={{ margin: 0, padding: 0 }}>Голосовать</Button>
        &nbsp;<Badge bg={bg}>{Math.abs(result)}</Badge>
      </Stack>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Голосование</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>{props.singer_one} vs {props.singer_two}</Modal.Title><br />
          <Container fluid>
            <Row className="align-items-center">
              <Col xs={12}><Captcha onCaptchaChanged={onCaptchaChanged} /></Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={votePro} disabled={wait}>Похоже <Badge bg="light" text="dark">{props.vote_pro}</Badge></Button>
          <Button variant="danger" onClick={voteCon} disabled={wait}>Совсем нет <Badge bg="light" text="dark">{props.vote_con}</Badge></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


function additionInt(x: any, y: any) {
  try {
    return "" + (Number.parseInt("" + x) + Number.parseInt("" + y));
  } catch {
    return x;
  }
}