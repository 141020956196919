import { useEffect, useState } from 'react';
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { LogService } from '../services/log.service';

export interface LabeledInputTextProps {
  label: string;
  value?: string;
  updateValue: (value: any) => void;
  isInvalid?: () => boolean | undefined;
}

export function LabeledInputText(props: LabeledInputTextProps) {
  const log = new LogService(LabeledInputText, "DEBUG");
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    props.updateValue(value);
  }, [value, props]);

  return (<>
    <InputGroup className="">
      <FloatingLabel label={props.label}>
        <Form.Control type="text" placeholder={props.label}
          onChange={(event) => setValue(event.target.value)}
          value={value} isInvalid={props.isInvalid && props.isInvalid()}
          className="fs-5" />
      </FloatingLabel>
    </InputGroup>
  </>);
}