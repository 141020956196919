import { useState } from 'react';

import Image from 'react-bootstrap/Image'

import { Button, Stack, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { LogService } from '../services/log.service';
import { RefreshCw } from 'react-feather';

export interface CaptchaProps {
  onCaptchaChanged: (captchaValue: string) => void;
  isInvalid?: () => boolean | undefined;
}

export function Captcha(props: CaptchaProps) {
  const defaultCaptchaUrl = "https://muz-plagiat.ru/api/index.php?component=captcha&action=image";
  const log = new LogService(Captcha, "DEBUG");
  const [captchaUrl, setCaptchaUrl] = useState(defaultCaptchaUrl);
  // const [captchaValue, setCaptchaValue] = useState("");
  const refreshCaptcha = () => setCaptchaUrl(defaultCaptchaUrl + "&nocache=" + Math.random());

  return (
    <>
      <Stack direction="horizontal">
        <Image src={captchaUrl} />
        <InputGroup className="">
          <Button variant="outline-secondary" onClick={refreshCaptcha} title="Обновить картинку"><RefreshCw /></Button>

          <FloatingLabel label="Введите текст на картинке">
            <Form.Control type="text" placeholder="Введите текст на картинке"
              onChange={(event) => props.onCaptchaChanged(event.target.value)}
              isInvalid={props.isInvalid && props.isInvalid()}
              className="fs-2" />
          </FloatingLabel>
        </InputGroup>
      </Stack>
    </>
  );
}
