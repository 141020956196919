import { useCallback, useEffect, useState } from 'react';
// import { LogService } from '../services/log.service';
import { progressService } from '../../services/progress.service';
import { CommentsActionService } from '../../services/twins-action.service';
import { PageTitle } from '../../PageTitle';
import { ApproveCommentsListItem } from './ApproveCommentsListItem';
import { useSearchParams } from 'react-router-dom';

export function ApproveComments() {
  // const log = new LogService(Search, "DEBUG");
  const [searchParams, setSearchParams] = useSearchParams();
  const [toApprove, setToApprove] = useState<boolean>(searchParams.get("approve") === "true" ? true : false);
  const [comments, setComments] = useState<any[]>([]);
  const [wait, setWait] = useState(false);
  const currentSearchParams: any = { page: "admin", "sub-page": "comments", }

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);
    let newSearchParams: any = currentSearchParams
    if (toApprove) {
      newSearchParams = { ...newSearchParams, approve: "" + toApprove };
    }

    setSearchParams({ ...newSearchParams });

    CommentsActionService({
      data: {
        action: "toApprove",
        params: { toApprove: toApprove }
      }
    }).then((data) => {
      if (data.result) {
        setComments(data.result);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, [toApprove])

  useEffect(() => {
    search();
  }, [search]);

  return (
    <>
      <PageTitle title={"Комментарии"} />
      <div className="form-check fs-4">
        <input
          checked={toApprove}
          onChange={(event) => setToApprove(event.target.checked)}
          className="form-check-input" type="checkbox" id="toApprove" />
        <label className="form-check-label" htmlFor="toApprove">
          На утверждение
        </label>
      </div>
      <div className="debug">
        <div className="">
          {!wait &&
            comments.map(
              (item: any) => {
                return (
                  <ApproveCommentsListItem {...item} key={item.id} />
                );
              },
            )
          }
        </div>
      </div>
    </>
  );
}