import { Row, Col, Card, Badge } from 'react-bootstrap';
import { TwinsListItemVote } from './TwinsListItemVote';

export interface TwinsListItemProps {
  id: string;
  action: string;
  approved: string;
  note: string;
  singer_one: string;
  singer_one_id: string;
  singer_two: string;
  singer_two_id: string;
  site: string;
  song_one: string;
  song_one_id: string;
  song_two: string;
  song_two_id: string;
  updated_time: string;
  created_time: string;
  vote_pro: string;
  vote_con: string;
  all_for_index: string;
  updateItem: (item: TwinsListItemProps) => {};
}

export function convertUnicode(input: string) {
  return input.replace(/\\+u([0-9a-fA-F]{6})/g, (a, b) =>
    String.fromCharCode(parseInt(b, 16)));
}

export function GoogleYandexLinks(props: { search: string }) {
  const q = encodeURIComponent(props.search);
  return (<>
    <Card.Link className="small fw-lighter" target={"_blank"} href={"https://www.google.com/search?q=" + q}>Google</Card.Link>
    <Card.Link className="small fw-lighter" target={"_blank"} href={"https://yandex.ru/search/?text=" + q}>Yandex</Card.Link>
  </>);
}

export function TwinsListItem(props: TwinsListItemProps) {

  return (
    <Card border="secondary" className="mb-3 mt-3">
      <Card.Body>
        <Card.Title>
          <Row>
            <Col><Card.Link className="" href={`/?page=search&text=${encodeURIComponent(props.singer_one)}`}>{props.singer_one}</Card.Link></Col>
            <Col><Card.Link className="" href={`/?page=search&text=${encodeURIComponent(props.singer_two)}`}>{props.singer_two}</Card.Link></Col>
          </Row>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <Row><Col>{props.song_one}</Col><Col>{props.song_two}</Col></Row>
          <Row className="small">
            <Col>
              <GoogleYandexLinks search={props.singer_one + " " + props.song_one} />
            </Col>
            <Col>
              <GoogleYandexLinks search={props.singer_two + " " + props.song_two} />
            </Col>
          </Row>
        </Card.Subtitle>
        <pre dangerouslySetInnerHTML={{ __html: props.note }} className="fw-lighter" style={{ whiteSpace: 'pre-wrap' }}></pre>
        {/* <br /> */}
        {/* <div dangerouslySetInnerHTML={{ __html: props.all_for_index }}></div>
        <br /> */}
        <Row className="small debug">
          <Col xs={9}>
            <TwinsListItemVote {...props} />
          </Col>
          <Col>
            <Badge bg="light" text="dark" className="mt-1 float-end">{new Date(props.created_time).toLocaleDateString("ru")}</Badge>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
