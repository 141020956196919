import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { LogService } from '../services/log.service';
import { PageTitle } from '../../PageTitle';
import { getAuthorization, getUsername, setAuthorization } from '../../services/auth.service';

import { ApproveTwins } from './ApproveTwins';
import { Login } from './Login';
import PageNotFound from '../PageNotFound';
import UpdateTwins from './UpdateTwins';
import { TwinsListItemProps } from '../TwinsListItem';
import { ApproveComments } from './ApproveComments';

export function Admin() {
  const [searchParams, setSearchParams] = useSearchParams();
  const param: string = searchParams.get("sub-page") || "approve";

  const [wait, setWait] = useState(false);
  const authorization = getAuthorization();

  const exit = () => {
    setAuthorization(null, null);
    setSearchParams({ page: "admin" });
  }

  return (
    <>
      {
        authorization && <PageTitle
          title={"Администрирование плагиата"}
          RightSideComponent={
            <>
              <span className="badge bg-light text-dark">{getUsername()}</span>&nbsp;
              <a href={`?page=admin&sub-page=twins&approve=true`} className="">Плагиат</a>&nbsp;
              <a href={`?page=admin&sub-page=comments&approve=true`} className="">Комменты</a>&nbsp;
              <button onClick={() => exit()} className="btn btn-outline-danger">Выйти</button>
            </>
          }
        />
      }
      {
        authorization &&
        (
          {
            "twins": <ApproveTwins />,
            "comments": <ApproveComments />,
          }[param] || <PageNotFound />
        )
      }
      {
        !authorization && <Login />
      }
    </>
  );
}