import { useCallback, useEffect, useState } from 'react';
// import { LogService } from '../services/log.service';
import { progressService } from '../services/progress.service';
import { PhraseActionService } from '../services/twins-action.service';
import { PageTitle } from '../PageTitle';

export function Authors() {
  // const log = new LogService(Search, "DEBUG");
  const [authors, setAuthors] = useState<any>({});
  const [index, setIndex] = useState<string>("");
  const [wait, setWait] = useState(false);

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);

    PhraseActionService({
      data: {
        action: "authors",
        params: {}
      }
    }).then((data) => {
      if (data.result) {
        const authorIndex = new Map<string, Set<string>>();
        // authorIndex.set("1", new Set());
        data.result.forEach((item: any) => {
          const a = item.value.charAt(0);
          let chapter = authorIndex.get(a);
          if (chapter === undefined) {
            chapter = new Set();
            authorIndex.set(a, chapter);
          }
          chapter.add(item.value);
        });
        setAuthors(authorIndex);
        console.log(Array.from(authorIndex.keys()));
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, [])

  useEffect(() => {
    !authors.keys && search();
  }, [authors, search]);

  function Item(props: any) {
    return <span className="badge bg-light m-1"><button
      onClick={() => setIndex(props.index)}
      //href={"/?page=search&text=" + encodeURIComponent(props.value)}
      className="btn btn-link"
      style={{ padding: 0, margin: 0 }}
    >
      {/* <div dangerouslySetInnerHTML={{ __html: props.value }}></div> */}{props.index}
    </button></span>
  }

  return (
    <>
      <PageTitle title={"Авторы"} />

      <div className="debug">
        {!wait && authors.keys &&
          Array.from(authors.keys()).map(
            (key: unknown) => {
              return (
                <Item {...{ index: key }} key={key} />
              );
            },
          )
        }
        <br />
        {index !== "" &&
          Array.from(authors.get(index)).map(
            (value: unknown) => {
              const name: string = "" + value;
              const clazz = "badge bg-light text-dark m-1 fs-3";
              const href = "/?page=search&text=" + encodeURIComponent(name);
              if (name.startsWith("&")) {
                return (
                  <a className={clazz} key={name} href={href}>
                    <div dangerouslySetInnerHTML={{ __html: name }}></div>
                  </a>
                );
              } else {
                return (
                  <a className={clazz} key={name} href={href}>
                    {name}
                  </a>
                );
              }
            },
          )
        }
      </div>
    </>
  );
}