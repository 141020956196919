import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Stack } from 'react-bootstrap';
import { LogService } from '../services/log.service';
import { progressService } from '../services/progress.service';
import { TwinsActionService } from '../services/twins-action.service';
import { AddSinger } from './AddSinger';
import { AddSong } from './AddSong';
import { Captcha } from './Captcha';
import { Notify } from "../Notifications";
import { PageTitle } from '../PageTitle';

export interface AddProps {
  //singerOne: string;
}

function Add(props: AddProps) {
  const log = new LogService(Add, "DEBUG");
  const [wait, setWait] = useState(false);
  const [added, setAdded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [singerOne, setSingerOne] = useState("");
  const [songOne, setSongOne] = useState("");
  const [singerTwo, setSingerTwo] = useState("");
  const [songTwo, setSongTwo] = useState("");
  const [note, setNote] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
  }, []);

  const onCaptchaChanged = (value: string) => {
    setCaptchaValue(value);
  }

  const checkValidValue = (value: string): boolean | undefined => {
    if (showError === true) {
      return (!value || value === "");
    } else {
      return false;
    }
  }

  const more = () => {
    setShowError(false);
    setAdded(false);
    setNote("");
  }

  const add = () => {
    const required = [singerOne, songOne, singerTwo, songTwo, captchaValue];
    for (const i in required) {
      if (!required[i] || required[i] === "") {
        setShowError(true);
        Notify({ error: "Заполните обязательные поля" });
        return;
      }
    }

    const unsubscriber = progressService.subscribe(setWait);
    TwinsActionService({
      data: {
        action: "add",
        params: {
          singerOne, songOne, singerTwo, songTwo, note, captcha: captchaValue
        }
      }
    }).then((data) => {
      setAdded(true);
    }).catch((err) => {
    }).finally(unsubscriber);
  }

  return (
    <>
      <PageTitle title={"Добавьте новый плагиат"} />
      <Stack className={"debug mb-5"} gap={3}>
        {added &&
          <>
            <div className="alert alert-success">Плагиат добавлен. После проверки вашей информации администратором она станет доступна в базе данных.</div>
            <Col xs="auto"><Button variant="outline-primary" onClick={() => more()} className="fs-5">Еще</Button></Col>
          </>
        }

        {!added && <Stack className={"debug"} gap={3}>


          <div className="alert alert-warning">Прежде чем добавлять запись, воспользуйтесь поиском. Возможно такая информация уже есть в нашей базе.</div>

          <h4>Исполнитель и композиция №1</h4>
          <AddSinger updateValue={(value: string) => setSingerOne(value)} isInvalid={() => checkValidValue(singerOne)} />
          <AddSong updateValue={(value: string) => setSongOne(value)} isInvalid={() => checkValidValue(songOne)} />

          <h4>Исполнитель и композиция №2</h4>
          <AddSinger updateValue={(value: string) => setSingerTwo(value)} isInvalid={() => checkValidValue(singerTwo)} />
          <AddSong updateValue={(value: string) => setSongTwo(value)} isInvalid={() => checkValidValue(songTwo)} />

          <FloatingLabel label="Введите комментарий">
            <Form.Control as="textarea" placeholder="Введите комментарий" rows={5}
              onChange={(event) => setNote(event.target.value)} style={{ height: "100px" }}
              value={note} className="fs-5" />
          </FloatingLabel>

          <Captcha onCaptchaChanged={onCaptchaChanged} isInvalid={() => checkValidValue(captchaValue)} />

          <Col xs="auto">
            <span className="btn btn-outline-primary fs-5" onClick={() => add()}>Сохранить</span>&nbsp;
            <a className="btn btn-outline-primary fs-5" href="/?page=search">Отмена</a>
          </Col>
        </Stack>}
      </Stack>
    </>
  );
}

export default Add;
