import { useCallback, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { PageTitle } from '../PageTitle';
import { progressService } from '../services/progress.service';
import { TwinsActionService } from '../services/twins-action.service';

function Top() {
  const [twins, setTwins] = useState<any[]>([]);
  const [wait, setWait] = useState(true);

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);

    TwinsActionService({ data: { action: "top" }, errorMode: "SILENT" }).then((data) => {
      if (data.result) {
        setTwins(data.result);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, []);

  useEffect(() => {
    twins.length === 0 && search();
  }, [twins, search]);

  function Word(props: any) {
    return <>
      <Badge bg="secondary">{props.count}</Badge>{" "}
      <a
        // onClick={() => {
        //   navigate("/search?text=" + encodeURIComponent(props.value)); return false;
        // }}
        href={"/?page=search&text=" + encodeURIComponent(props.value)}
        className="text-muted"
        style={{ padding: 0, margin: 0, marginRight: 0, paddingBottom: -5, paddingTop: -5, }}>{props.value}</a>
      <br></br>
    </>
  }

  let i = 0;
  return (
    <>
      {!wait && twins.length > 0 &&
        <div className={"debug no-gap"} style={{ marginTop: 10, }}>
          <PageTitle title="Топчик" />
          <h5 className="text-muted">Победители по колличеству плагиата</h5>

          {
            twins.map(
              (item: any) => {
                return (
                  <Word {...item} key={i++} />
                );
              },
            )
          }

        </div>
      }
    </>
  );
}

export default Top;
