import { Observable } from "./observable";

export const progressService = new Observable<boolean>(false);

export function progressStart() {
  progressService.set(true);
}

export function progressStop() {
  progressService.set(false);
}