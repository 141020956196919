import { BrowserRouter, useSearchParams } from 'react-router-dom';

import { Content } from './Content';
import { Header } from './Header';

import './App.css';
import Keywords from './pages/Keywords';
import Top from './pages/Top';
import { LogService } from './services/log.service';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VoteTop from './pages/VoteTop';
import Stat from './pages/Stat';
import { getAuthorization } from './services/auth.service';

LogService.setEnable(true);
function App() {
  const authorization = getAuthorization();
  return (
    <BrowserRouter>
      <Header />
      <Content />
      {/* <div className="container-fluid row">
        <div className="col-md-1" />
        <div className="col-md-11">
          <div className="row">
            <div className="col-md-8">
              <Header />
              <Content />
            </div>
            { false && authorization === null && <div className="col-md-4">
              <Stat />
              <Keywords />
              <Top />
              <VoteTop />
            </div>}
          </div>
        </div>
      </div> */}
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App;
