import { useCallback, useEffect, useState } from 'react';
import { progressService } from '../services/progress.service';
import { TwinsActionService } from '../services/twins-action.service';

function Stat() {
  const [stat, setStat] = useState<any>(undefined);
  const [wait, setWait] = useState(true);

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);

    TwinsActionService({
      data: {action: "stat"}, errorMode: "SILENT"
    }).then((data) => {
      if (data.result) {
        setStat(data.result);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, []);

  useEffect(() => {
    !stat && search();
  }, [stat, search]);

  function Item(props: any) {
    return <>
      <span className="position-relative">
        <span className="fs-5">{props.name}<span className="text-white">___</span></span>
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {props.count}
        </span>
      </span><br/>
    </>
  }

  let i = 0;
  return (
    <>
      {!wait &&
        <div className={"debug no-gap"} style={{ marginTop: 10, }}>
          {stat &&
            <>
              {stat.map(
                (item: any) => {
                  return (
                    <Item {...{ name: item.category, count: item.count }} />
                  );
                }
              )
              }
            </>
          }
        </div>
      }
    </>
  );
}

export default Stat;
