import { ApiService, RequestParams } from "./api.service";



export class ActionData extends RequestParams {
  component?: string;
  action?: string;
  params?: object;
}

export class ActionParams extends RequestParams {
  data?: ActionData;
}

export async function ActionService(params: ActionParams) {
  return ApiService({
    ...params,
    // url: "http://home.lukastoev.ru/twins-api/index.php",
    url: "https://muz-plagiat.ru/api/index.php",
    method: 'POST',
  });
}

export async function TwinsActionService(params: ActionParams) {
  return ActionService(
    { ...params, data: { ...params.data, component: "twins" } }
  );
}

export async function CommentsActionService(params: ActionParams) {
  return ActionService(
    { ...params, data: { ...params.data, component: "comments" } }
  );
}

export async function PhraseActionService(params: ActionParams) {
  return ActionService(
    { ...params, data: { ...params.data, component: "phrase" } }
  );
}