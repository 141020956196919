import { PageTitle } from '../PageTitle';
import TelegramPost from './admin/TelegramPost';

function PageNotFound() {

  return (
    <>
      <div id="msg123" className={"debug no-gap"} style={{ marginTop: 10, }}>
        <PageTitle title="404 Страница не найдена" />
        {/* <TelegramPost/> */}
      </div>
    </>
  );
}

export default PageNotFound;
