import { BrowserRouter, useSearchParams } from 'react-router-dom';

import { Content } from './Content';
import { Header } from './Header';

import './App.css';
import Keywords from './pages/Keywords';
import Top from './pages/Top';
import { LogService } from './services/log.service';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VoteTop from './pages/VoteTop';
import Stat from './pages/Stat';
import { getAuthorization } from './services/auth.service';

LogService.setEnable(true);
function AppTopRightSide() {
  const authorization = getAuthorization();
  return (
    <BrowserRouter>
      <Keywords />
    </BrowserRouter>
  )
}

export default AppTopRightSide;
