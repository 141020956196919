import { useCallback, useEffect, useState } from 'react';
// import { LogService } from '../services/log.service';
import { progressService } from '../services/progress.service';
import { CommentsActionService } from '../services/twins-action.service';
import { PageTitle } from '../PageTitle';
import { CommentsListItem } from './CommentsListItem';

export function Comments() {
  // const log = new LogService(Search, "DEBUG");
  const [comments, setComments] = useState<any[]>([]);
  const [wait, setWait] = useState(false);

  const search = useCallback(() => {
    const unsubscriber = progressService.subscribe(setWait);

    CommentsActionService({
      data: {
        action: "search",
        params: {}
      }
    }).then((data) => {
      if (data.result) {
        setComments(data.result);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }, [])

  useEffect(() => {
    search();
  }, [search]);

  return (
    <>
      <PageTitle title={"Последние комментарии"}
        RightSideComponent={<a href="/?page=add_comment" className="btn btn-outline-primary">Написать</a>}
      />
      <div className="debug">
        <div className="">
          {!wait &&
            comments.map(
              (item: any) => {
                return (
                  <CommentsListItem {...item} key={item.id} />
                );
              },
            )
          }
        </div>
      </div>
    </>
  );
}