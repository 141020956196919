import { useState } from "react";
import { progressService } from "../../services/progress.service";
import { CommentsActionService } from "../../services/twins-action.service";
import { CommentsListItemProps } from "../CommentsListItem";

export interface ApproveCommentsListItemProps extends CommentsListItemProps {
  action: string;
  updateItem: (item: ApproveCommentsListItemProps) => {};
}

export function ApproveCommentsListItem(props: ApproveCommentsListItemProps) {
  const [wait, setWait] = useState(false);

  const action = (action: string) => {
    const unsubscribe = progressService.subscribe(setWait);
    CommentsActionService({
      data: {
        action: action,
        params: { id: props.id }
      }
    }).then((result) => {
      props.updateItem({ ...props, action: action });
    }).catch((err) => {
    }).finally(unsubscribe);
  };

  const approveAction = props.approved === "1" ? "unapprove" : "approve";
  const approveTitle = props.approved === "1" ? "Запретить" : "Одобрить";
  const approveClass = props.approved === "1" ? "btn btn-outline-danger" : "btn btn-outline-success";

  return (
    <>
      <div className="card border-light text-dark bg-light mb-3">
        <div className="card-body">
          <blockquote className="blockquote mb-0">
            <div dangerouslySetInnerHTML={{ __html: props.note }}></div>
          </blockquote>
        </div>
        <div className="card-footer border-light">
          <button onClick={() => action(approveAction)} className={approveClass}>{approveTitle}</button>&nbsp;
          <button onClick={() => action("delete")} className="btn btn-outline-danger">Удалить</button>

          <span className="badge rounded-pill bg-light text-dark mt-1">{props.name}</span>
          <span className="badge rounded-pill bg-light text-dark mt-1 float-end">{new Date(props.post_date).toLocaleDateString("ru")} {new Date(props.post_date).toLocaleTimeString("ru")}</span>
        </div>
      </div>
    </>
  );
}
