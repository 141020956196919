export interface CommentsListItemProps {
  id: string;
  approved: string;
  name: string;
  email: string;
  note: string;
  post_date: string;
}

export function CommentsListItem(props: CommentsListItemProps) {

  return (
    <>
      <div className="card border-light text-dark bg-light mb-3">
        <div className="card-body">
          <blockquote className="blockquote mb-0">
            <div dangerouslySetInnerHTML={{ __html: props.note }}></div>
          </blockquote>
        </div>
        <div className="card-footer border-light">
          <span className="badge rounded-pill bg-light text-dark mt-1">{props.name}</span>
          <span className="badge rounded-pill bg-light text-dark mt-1 float-end">{new Date(props.post_date).toLocaleDateString("ru")} {new Date(props.post_date).toLocaleTimeString("ru")}</span>
        </div>
      </div>
    </>
  );
}
