import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { progressService } from '../services/progress.service';
import { TwinsActionService } from '../services/twins-action.service';

function Keywords() {
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState<any[]>([]);
  const [wait, setWait] = useState(false);

  const search = () => {
    const unsubscriber = progressService.subscribe(setWait);
    TwinsActionService({
      data: { action: "keywords", }, errorMode: "SILENT"
    }).then((data) => {
      if (data.result) {
        setKeywords(data.result);
      }
    }).catch((err) => {
    }).finally(unsubscriber);
  }

  useEffect(() => {
    keywords.length === 0 && search();
  }, [keywords]);

  function Word(props: any) {
    let size = 10;
    let len = props.value.length;
    if (len > 0) {
      size = 200 / len;
      if (size < 10) {
        size = 10;
      } else {
        if (size > 30) {
          size = 30;
        }
      }
    }
    return <><a
      // onClick={() => {
      //   navigate("/search?text=" + encodeURIComponent(props.value)); return false;
      // }}
      href={"/?page=search&text=" + encodeURIComponent(props.value)}
      className="text-nowrap text-muted"
      style={{ fontSize: size, }}>{props.value}</a>{" "}</>
  }

  let i = 0;
  return (
    <div className={"no-gap debug"} >
      {//!wait &&
        keywords.map(
          (item: any) => {
            return (
              <Word {...item} key={i++} />
            );
          },
        )
      }
    </div>
  );
}

export default Keywords;
