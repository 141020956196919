import AppNavigation from './Navigation';

export function Header() {

  return (
    <>
      <h2 className="text-muted">
        <img src="/favicon.svg" alt="" width={60} className="d-inline" />
        Музыкальный пагиат
      </h2>

      <AppNavigation />
    </>
  );
}
