import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Stack } from 'react-bootstrap';
import { LogService } from '../services/log.service';
import { progressService } from '../services/progress.service';
import { CommentsActionService } from '../services/twins-action.service';
import { Captcha } from './Captcha';
import { Notify } from "../Notifications";
import { PageTitle } from '../PageTitle';
import { LabeledInputText } from './LabeledInputText';

export interface AddCommentProps { // extends CommentsListItemProps {
}

function AddComment(props: AddCommentProps) {
  const log = new LogService(AddComment, "DEBUG");
  const [wait, setWait] = useState(false);
  const [added, setAdded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
  }, []);

  const onCaptchaChanged = (value: string) => {
    setCaptchaValue(value);
  }

  const checkValidValue = (value: string): boolean | undefined => {
    if (showError === true) {
      return (!value || value === "");
    } else {
      return false;
    }
  }

  const more = () => {
    setShowError(false);
    setAdded(false);
    setNote("");
  }

  const add = () => {
    const required = [note, captchaValue];
    for (const i in required) {
      if (!required[i] || required[i] === "") {
        setShowError(true);
        Notify({ error: "Заполните обязательные поля" });
        return;
      }
    }

    const unsubscriber = progressService.subscribe(setWait);
    CommentsActionService({
      data: {
        action: "add",
        params: {
          name, email, note, captcha: captchaValue
        }
      }
    }).then((data) => {
      log.debug("add commenmt ok", data);
      setAdded(true);
    }).catch((err) => {
    }).finally(unsubscriber);
  }

  return (
    <>
      <PageTitle title={"Добавьте новое сообщение"} />
      <Stack className={"debug mb-5"} gap={3}>
        {added &&
          <>
            <div className="alert alert-success">Сообщение добавлено. После проверки информации администратором она появится на сайте.</div>
            <Col xs="auto"><Button variant="outline-primary" onClick={() => more()} className="fs-5">Еще</Button></Col>
          </>
        }

        {!added && <Stack className={"debug"} gap={3}>
          <FloatingLabel label="Введите комментарий">
            <Form.Control as="textarea" placeholder="Введите комментарий" rows={5}
              onChange={(event) => setNote(event.target.value)} style={{ height: "100px" }}
              isInvalid={checkValidValue(note)}
              value={note} className="fs-5" />
          </FloatingLabel>

          <Col xs="auto"><Captcha onCaptchaChanged={onCaptchaChanged} isInvalid={() => checkValidValue(captchaValue)} /></Col>


          <LabeledInputText label="Имя (не обязательно):" value={name} updateValue={(value: string) => setName(value)} />
          <LabeledInputText label="Почта (не обязательно):" value={email} updateValue={(value: string) => setEmail(value)} />


          <Col xs="auto">
            <span className="btn btn-outline-primary fs-5" onClick={() => add()}>Сохранить</span>&nbsp;
            <a className="btn btn-outline-primary fs-5" href="/?page=comments">Отмена</a>
          </Col>
        </Stack>}
      </Stack>
    </>
  );
}

export default AddComment;
