import { Button, Row, Col, Card, Placeholder } from 'react-bootstrap';

function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export function PlaceholderText(props: any) {
  const words = [];
  const wordCount = props.count;
  const maxLen = 5;
  const minLen = 2;
  for (let i = 0; i < wordCount; i++) {
    const len = Math.floor(Math.random() * maxLen) + minLen;
    words.push(len);
  }
  const id = guid();
  return (<>
    {
      words.map((len, index) => {
        return <Placeholder key={index + "-" + id} xs={len} style={{marginRight: 4}}/>
      })
    }
  </>);
}

export function TwinsListItemPlaceholder() {
  // const log = new LogService(TwinsListItemPlaceholder, "DEBUG");

  return (
    <Card border="primary" className="mb-3" style={{}}>
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow">
          <Row><Col><Placeholder xs={9} /></Col><Col><Placeholder xs={10} /></Col></Row>
        </Placeholder>
        <Placeholder as={Card.Subtitle} animation="wave">
          <Row>
            <Col>
              <PlaceholderText count={3}/>
            </Col>
            <Col>
              <PlaceholderText count={4}/>
            </Col>
          </Row>
        </Placeholder>
        <Placeholder as={Card.Text} animation="wave">
          <PlaceholderText count={7} />
        </Placeholder>
      </Card.Body>
      <Card.Footer className="inherit-bg debug">
        <Button variant="link" size="sm" disabled>Google</Button>
        <Button variant="link" size="sm"  disabled>Yandex</Button>
      </Card.Footer>
    </Card>
  );
}
