import { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Stack } from 'react-bootstrap';
import { LogService } from '../../services/log.service';
import { progressService } from '../../services/progress.service';
import { TwinsActionService } from '../../services/twins-action.service';
import { AddSinger } from '../AddSinger';
import { AddSong } from '../AddSong';
import { Captcha } from '../Captcha';
import { Notify } from "../../Notifications";
import { PageTitle } from '../../PageTitle';
import { TwinsListItemProps } from '../TwinsListItem';

export interface UpdateTwinsItemProps extends TwinsListItemProps {
}

function UpdateTwins(props: UpdateTwinsItemProps) {
  const log = new LogService(UpdateTwins, "DEBUG");
  const [wait, setWait] = useState(false);
  const [added, setAdded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [singerOne, setSingerOne] = useState(props.singer_one);
  const [songOne, setSongOne] = useState(props.song_one);
  const [singerTwo, setSingerTwo] = useState(props.singer_two);
  const [songTwo, setSongTwo] = useState(props.song_two);
  const [note, setNote] = useState(props.note);

  useEffect(() => {
  }, []);

  const checkValidValue = (value: string): boolean | undefined => {
    if (showError === true) {
      return (!value || value === "");
    } else {
      return false;
    }
  }

  const update = () => {
    const required = [singerOne, songOne, singerTwo, songTwo];
    for (const i in required) {
      if (!required[i] || required[i] === "") {
        setShowError(true);
        Notify({ error: "Заполните обязательные поля" });
        return;
      }
    }

    const unsubscriber = progressService.subscribe(setWait);
    TwinsActionService({
      data: {
        action: "update",
        params: {
          twins_id: props.id, singerOne, songOne, singerTwo, songTwo, note
        }
      }
    }).then((data) => {
      setAdded(true);
    }).catch((err) => {
    }).finally(unsubscriber);
  }

  return (
    <>
      <PageTitle title={"Радактирование плагиата"} />
      <Stack className={"debug mb-5"} gap={3}>
        {<Stack className={"debug"} gap={3}>

          <h4>Исполнитель и композиция №1</h4>
          <AddSinger updateValue={(value: string) => setSingerOne(value)} isInvalid={() => checkValidValue(singerOne)} value={singerOne} />
          <AddSong updateValue={(value: string) => setSongOne(value)} isInvalid={() => checkValidValue(songOne)} value={songOne} />

          <h4>Исполнитель и композиция №2</h4>
          <AddSinger updateValue={(value: string) => setSingerTwo(value)} isInvalid={() => checkValidValue(singerTwo)} value={singerTwo} />
          <AddSong updateValue={(value: string) => setSongTwo(value)} isInvalid={() => checkValidValue(songTwo)} value={songTwo} />

          <FloatingLabel label="Введите комментарий">
            <Form.Control as="textarea" placeholder="Введите комментарий" rows={5}
              onChange={(event) => setNote(event.target.value)} style={{ height: "100px" }}
              value={note} className="fs-5" />
          </FloatingLabel>

          <Col xs="auto">
            <span className="btn btn-outline-primary fs-5" onClick={() => update()}>Сохранить</span>&nbsp;
            <a className="btn btn-outline-primary fs-5" href="/?page=admin">Отмена</a>
          </Col>
        </Stack>}
      </Stack>
    </>
  );
}

export default UpdateTwins;
